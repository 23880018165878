.playground-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

/* Profile Image */
.profileImage {
  text-align: center;
}
.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
}

/* Text Component */
.text-comp {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
}
.text-comp-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}
.text-comp h2, .text-comp p {
  margin: 0;
  color: #555;
}

/* Credentials Form */
.credentials-comp {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
}
.credentials-comp-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}
.credentials-comp b {
  display: inline-block;
  margin-top: 10px;
}
.credentials-comp input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.credentials-comp button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Information Form */
.info-form {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
}
.info-form h1 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}
.info-form b {
  display: inline-block;
  margin-top: 10px;
}
.info-inputs .name-input input, 
.info-inputs .address-textarea textarea, 
.info-inputs select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.info-inputs .country-dropdown select,
.info-inputs .hobbies-checkbox label,
.info-inputs .gender-radio label {
  display: inline-block;
  margin-right: 10px;
}
.info-inputs .hobbies-checkbox, 
.info-inputs .gender-radio {
  margin-top: 10px;
}
.info-inputs .address-textarea textarea {
  height: 80px;
}
.info-form button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

/* Enumeration Component */
.enum-comp {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
}
.enum-comp-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}
.enum-comp h3 {
  color: #333;
  margin-top: 15px;
}
.all-options, .selected-options {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
}
.all-enum-options, .selected-enum-options {
  padding: 5px 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Multi Media Component */
.multi-media-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}
.multi-media-comp {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.multi-media-file {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #ddd;
}
.multi-media-file img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
