.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: white;
    // background: rgba(255, 255, 255, 0);
    // backdrop-filter: blur(3px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;

    .spinner {
        // Add styling for your loader spinner or animation
        border: 4px solid #e2e2e2;
        border-top: 4px solid #0146f4;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;


        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }
}

.iconify-img {
    width: 64px;
    height: 64px;
    object-fit: cover;
    object-position: center;
}